/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Pathway',
        import: () => import(/* webpackChunkName: "Pathway" */ '@stories/Widgets/Pathway/Pathway'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Form',
        import: () => import(/* webpackChunkName: "Form" */ '@stories/Widgets/Form/Form'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'ArticleListing',
        import: () => import(/* webpackChunkName: "ArticleListing" */ '@stories/Widgets/ArticleListing/ArticleListing'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Breadcrumb',
        import: () => import(/* webpackChunkName: "Breadcrumb" */ '@stories/Widgets/Global/Breadcrumb/Breadcrumb'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CourseInPageNavigation',
        import: () => import(/* webpackChunkName: "CourseInPageNavigation" */ '@stories/Widgets/CourseInPageNavigation/CourseInPageNavigation'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'ImageText5050',
        import: () => import(/* webpackChunkName: "ImageText5050" */ '@stories/Widgets/ImageText5050/ImageText5050'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'HomePageHero',
        import: () => import(/* webpackChunkName: "HomePageHero" */ '@stories/Widgets/HomePageHero/HomePageHero'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'RichText',
        import: () => import(/* webpackChunkName: "RichText" */ '@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CtaBanner',
        import: () => import(/* webpackChunkName: "CtaBanner" */ '@stories/Widgets/CtaBanner/CtaBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'IconList',
        import: () => import(/* webpackChunkName: "IconList" */ '@stories/Widgets/IconList/IconList'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'ImageWithCaption',
        import: () => import(/* webpackChunkName: "ImageWithCaption" */ '@stories/Components/Image/ImageWithCaption'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'InnerTextBanner',
        import: () => import(/* webpackChunkName: "InnerTextBanner" */ '@stories/Widgets/InnerTextBanner/InnerTextBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'LogoList',
        import: () => import(/* webpackChunkName: "LogoList" */ '@stories/Widgets/LogoList/LogoList'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'LatestArticles',
        import: () => import(/* webpackChunkName: "LatestArticles" */ '@stories/Widgets/LatestArticles/LatestArticles'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'CategoryCardList',
        import: () => import(/* webpackChunkName: "CategoryCardList" */ '@stories/Widgets/CategoryCardList/CategoryCardList'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'TrustPilot',
        import: () => import(/* webpackChunkName: "TrustPilot" */ '@stories/Widgets/TrustPilot/TrustPilot'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'WhatsIncluded',
        import: () => import(/* webpackChunkName: "WhatsIncluded" */ '@stories/Widgets/WhatsIncluded/WhatsIncluded'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CourseOverview',
        import: () => import(/* webpackChunkName: "CourseOverview" */ '@stories/Widgets/CourseOverview/CourseOverview'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'StatisticsCallOut',
        import: () => import(/* webpackChunkName: "StatisticsCallOut" */ '@stories/Widgets/StatisticsCallOut/StatisticsCallOut'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'PartnerList',
        import: () => import(/* webpackChunkName: "PartnerList" */ '@stories/Widgets/PartnerList/PartnerList'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SearchListing',
        import: () => import(/* webpackChunkName: "SearchListing" */ '@stories/Widgets/Searchlisting/Searchlisting'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'SignpostCards',
        import: () => import(/* webpackChunkName: "SignpostCards" */ '@stories/Widgets/SignpostCards/SignpostCards'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'MeetTheTeamList',
        import: () => import(/* webpackChunkName: "MeetTheTeamList" */ '@stories/Widgets/MeetTheTeamList/MeetTheTeamList'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'InnerPageBanner',
        import: () => import(/* webpackChunkName: "InnerPageBanner" */ '@stories/Widgets/InnerPageBanner/InnerPageBanner'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'FAQs',
        import: () => import(/* webpackChunkName: "FAQs" */ '@stories/Widgets/FAQs/FAQs'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'WhatYoullLearn',
        import: () => import(/* webpackChunkName: "WhatYoullLearn" */ '@stories/Widgets/WhatYoullLearn/WhatYoullLearn'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CourseLeader',
        import: () => import(/* webpackChunkName: "CourseLeader" */ '@stories/Widgets/CourseLeader/CourseLeader'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SeminarsList',
        import: () => import(/* webpackChunkName: "SeminarsList" */ '@stories/Widgets/SeminarsList/SeminarsList'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'StudentSuccessVideoListing',
        import: () => import(/* webpackChunkName: "StudentSuccessVideoListing" */ '@stories/Widgets/StudentSuccessVideoListing/StudentSuccessVideoListing'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'FeaturedCourses',
        import: () => import(/* webpackChunkName: "FeaturedCourses" */ '@stories/Widgets/FeaturedCourses/FeaturedCourses'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'CoursePricingCardList',
        import: () => import(/* webpackChunkName: "CoursePricingCardList" */ '@stories/Widgets/CoursePricingCardList/CoursePricingCardList'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'Testimonials',
        import: () => import(/* webpackChunkName: "Testimonials" */ '@stories/Widgets/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'VideoComponent',
        import: () => import(/* webpackChunkName: "VideoComponent" */ '@stories/Widgets/VideoComponent/VideoComponent'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'CourseContentDownload',
        import: () => import(/* webpackChunkName: "CourseContentDownload" */ '@stories/Widgets/CourseContentDownload/CourseContentDownload'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'ContactUsDetails',
        import: () => import(/* webpackChunkName: "ContactUsDetails" */ '@stories/Widgets/ContactUsDetails/ContactUsDetails'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SalaryIndicator',
        import: () => import(/* webpackChunkName: "SalaryIndicator" */ '@stories/Widgets/SalaryIndicator/SalaryIndicator'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CourseListing',
        import: () => import(/* webpackChunkName: "CourseListing" */ '@stories/Widgets/CourseListing/CourseListing'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'SignUpOverviewProcess',
        import: () => import(/* webpackChunkName: "SignUpOverviewProcess" */ '@stories/Widgets/SignUpOverviewProcess/SignUpOverviewProcess'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'HtmlSitemap',
        import: () => import(/* webpackChunkName: "HtmlSitemap" */ '@stories/Widgets/HtmlSitemap/HtmlSitemap'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'OfferPageBanner',
        import: () => import(/* webpackChunkName: "OfferPageBanner" */ '@stories/Widgets/OfferPageBanner/OfferPageBanner'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'VideoText',
        import: () => import(/* webpackChunkName: "VideoText" */ '@stories/Widgets/VideoText/VideoText'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'UspCards',
        import: () => import(/* webpackChunkName: "UspCards" */ '@stories/Widgets/UspCards/UspCards'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'OfferFormBlock',
        import: () => import(/* webpackChunkName: "OfferFormBlock" */ '@stories/Widgets/OfferFormBlock/OfferFormBlock'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'LogInForm',
        import: () => import(/* webpackChunkName: "LogInForm" */ '@stories/Widgets/LogInForm/LogInForm'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'BlogListing',
        import: () => import(/* webpackChunkName: "LogInForm" */ '@stories/Widgets/BlogListing/BlogListing'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'SplitAuthorMedia',
        import: () => import(/* webpackChunkName: "LogInForm" */ '@stories/Widgets/SplitAuthorMedia/SplitAuthorMedia'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'InnerCta',
        import: () => import(/* webpackChunkName: "LogInForm" */ '@stories/Widgets/InnerCta/InnerCta'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'NewsletterForm',
        import: () => import(/* webpackChunkName: "NewsletterForm" */ '@stories/Widgets/NewsletterForm/NewsletterForm'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'JobAnnouncement',
        import: () => import(/* webpackChunkName: "NewsletterForm" */ '@stories/Widgets/JobAnnouncement/JobAnnouncement'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'LogoCarousel',
        import: () => import(/* webpackChunkName: "LogoCarousel" */ '@stories/Widgets/LogoCarousel/LogoCarousel'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'ContentNavigation',
        import: () => import(/* webpackChunkName: "ContentNavigation" */ '@stories/Widgets/ContentNavigation/ContentNavigation'),
        config: {
            hydrate: 'always',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'CourseModules',
        import: () => import(/* webpackChunkName: "CourseModules" */ '@stories/Widgets/CourseModules/CourseModules'),
        config: {
            hydrate: 'in-view',
            blockedByOnetrust: true,
        },
    },
    {
        name: 'ZohoRecruitEmbed',
        import: () => import(/* webpackChunkName: "ZohoRecruitEmbeding" */ '@stories/Widgets/ZohoRecruitEmbed/ZohoRecruitEmbed'),
        config: {
            hydrate: 'always',
        },
    },
];
